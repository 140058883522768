import { Form } from "@remix-run/react";
import { useRef } from "react";

import { useTranslate } from "~/hooks/useTranslate";
import { Button, Input, Label, Textarea } from "~/components";

import { useContactForm } from "~/hooks/useContactForm";
import { Checkbox } from "../ui/checkbox";
import Markdown from "react-markdown";

export function ContactForm() {
  const { translate } = useTranslate();

  const form = useRef<HTMLFormElement>(null);

  const { submit, isSubmitting, validationErrors } = useContactForm({
    form: form.current,
  });

  return (
    <Form onSubmit={submit} ref={form} id="contact-form" className="space-y-6">
      <div className="space-y-2">
        <Label htmlFor="firstName">{translate("contact.form.name")}</Label>
        <Input
          id="firstName"
          name="firstName"
          defaultValue=""
          placeholder={translate(
            "contact.form.placeholder.firstName"
          ).toString()}
        />
        {validationErrors?.firstName && (
          <p className="text-destructive text-[1rem]">
            {translate(validationErrors.firstName)}
          </p>
        )}
      </div>
      <div className="space-y-2">
        <Label htmlFor="lastName">{translate("contact.form.surname")}</Label>
        <Input
          id="lastName"
          name="lastName"
          defaultValue=""
          placeholder={translate(
            "contact.form.placeholder.lastName"
          ).toString()}
        />
        {validationErrors?.lastName && (
          <p className="text-destructive text-[1rem]">
            {translate(validationErrors.lastName)}
          </p>
        )}
      </div>
      <div className="space-y-2">
        <Label htmlFor="email">{translate("contact.form.email")}</Label>
        <Input
          id="email"
          name="email"
          type="email"
          defaultValue=""
          placeholder={translate("contact.form.placeholder.email").toString()}
        />
        {validationErrors?.email && (
          <p className="text-destructive text-[1rem]">
            {translate(validationErrors.email)}
          </p>
        )}
      </div>
      <div className="space-y-2">
        <Label htmlFor="message">{translate("contact.form.message")}</Label>
        <Textarea
          id="message"
          name="message"
          defaultValue=""
          rows={5}
          placeholder={translate("contact.form.placeholder.message").toString()}
        />
        {validationErrors?.message && (
          <p className="text-destructive text-[1rem]">
            {translate(validationErrors.message)}
          </p>
        )}
      </div>

      <div className="items-top flex space-x-2 mt-3">
        <Checkbox
          id="newsletter"
          name="newsletter"
          className="border-secondary"
        />
        <div className="grid  leading-none">
          <label htmlFor="newsletter" className="mb-0 text-muted-foreground">
            {translate("form.subscribe.newsletter")}
          </label>
          <Markdown
            components={{
              p: ({ children }) => (
                <p className="m-0 text-[0.9rem] text-muted-foreground">
                  {children}
                </p>
              ),
              a: ({ children, href }) => (
                <a
                  className="m-0 text-[0.9rem] normal-case text-secondary"
                  target="_blank"
                  href={href}
                  rel="noreferrer"
                >
                  {children}
                </a>
              ),
            }}
          >
            {translate("newsletter.form.disclaimer") + ""}
          </Markdown>
        </div>
      </div>

      <Button
        variant="secondary"
        disabled={isSubmitting}
        className="w-full md:w-fit"
      >
        {translate("btn.contact.form.submit")}
      </Button>
    </Form>
  );
}
